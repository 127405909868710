import Table from 'react-bootstrap/Table';

export default function RoadInfo({ info }) {
  return (
    <div style={{ paddingTop: '1em', paddingBottom: '1em' }}>
      <h4>Road Attributes</h4>
      {info && (
        <Table
          responsive
          striped
          bordered
          hover
          style={{ whiteSpace: 'nowrap', width: '1%' }}
          size='sm'
          variant='dark'
          className='text-light text-small'
        >
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(info).map((x, j) => (
              <tr key={'road_info_row' + j}>
                <td key={x[0] + j}>{x[0]}</td>
                <td key={x[1] + j}>{x[1]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}
