import Pagination from 'react-bootstrap/Pagination';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

export default function MyPagination({
  current,
  last,
  itemsPerPage,
  handItemsPerPageChange,
  handlePageChange
}) {
  return (
    <div>
      <div className='pg-count'>
        Page {current} / {last}
      </div>
      <Pagination>
        <Pagination.First
          disabled={current === 1}
          onClick={() => handlePageChange(1)}
        />
        <Pagination.Prev
          disabled={current === 1}
          onClick={() => handlePageChange(current - 1)}
        />

        {current !== 1 ? (
          <Pagination.Item onClick={() => handlePageChange(current - 1)}>
            {current - 1}
          </Pagination.Item>
        ) : null}
        <Pagination.Item active>{current}</Pagination.Item>
        {current !== last && (
          <Pagination.Item onClick={() => handlePageChange(current + 1)}>
            {current + 1}
          </Pagination.Item>
        )}

        <Pagination.Next
          disabled={current === last}
          onClick={() => handlePageChange(current + 1)}
        />
        <Pagination.Last
          disabled={current === last}
          onClick={() => handlePageChange(last)}
        />

        <DropdownButton
          title={`Items Per Page: ${itemsPerPage}`}
          id='dropdown-menu-scenario-items-per-page'
          variant='warning'
          menuVariant='dark'
          className='items-per-pg-dropdown'
          onSelect={(e) => {
            handItemsPerPageChange(e);
          }}
        >
          <Dropdown.Item as='button' eventKey={10}>
            10
          </Dropdown.Item>
          <Dropdown.Item as='button' eventKey={20}>
            20
          </Dropdown.Item>
          <Dropdown.Item as='button' eventKey={30}>
            30
          </Dropdown.Item>
          <Dropdown.Item as='button' eventKey={40}>
            40
          </Dropdown.Item>
          <Dropdown.Item as='button' eventKey={50}>
            50
          </Dropdown.Item>
        </DropdownButton>
      </Pagination>
    </div>
  );
}
