import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import njdot_icon from '../images/njdot_icon.png';
import workflow from '../images/workflow.png';
import arrows from '../images/arrows.svg';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

export default function InfoModal({ showInfoModal, handleInfoModalClose }) {
  return (
    <Modal
      size='lg'
      show={showInfoModal}
      onHide={handleInfoModalClose}
      keyboard={false}
      aria-labelledby='contained-modal-title-vcenter'
      dialogClassName='info-splash-modal text-light'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className='margin-left-2em'>
            <img
              src={njdot_icon}
              height='75px'
              className='logo'
              alt='NJDOT icon'
            />
            NJDOT Criticality Assessment Tool
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row>
            <h5 className='margin-sides-2em'>
              Use this tool to identify the criticality of road segments and the
              bridge crossing for input to project screening and prioritization.
              Criticality qualitatively represents the consequence of asset
              unavailability based on these key factors.
            </h5>
            <center>
              <img
                src={workflow}
                className='workflow-img'
                alt='Asset assessment workflow diagram'
                workflow
              />
            </center>
          </Row>
          <Row>
            <div className='side-by-side-modal'>
              <img
                src={arrows}
                className='arrows-icon'
                width='110px'
                height='110px'
                alt='Three arrows pointing right icon'
              />
              <div>
                <h4>
                  <b>Create a Scenario</b>
                </h4>
                <p className='paragraph-indented'>
                  Twelve factors are included in the criticality analysis,
                  covering safety and resilience, maintenance and operations,
                  and socioeconomic conditions. View results for specific roads
                  and bridges by exploring the map and clicking on specific
                  assets. Criticality rankings are sorted into five equal
                  quintiles. For example, the top 20% of roads are considered to
                  have Very High criticality.
                </p>
                <h4>
                  <b>Compare Scenarios</b>
                </h4>
                <p className='paragraph-indented'>
                  Select up to three saved criticality ranking scenarios to
                  compare results. View results as a map or table.
                </p>
                <h4>
                  <b>View Individual Factors</b>
                </h4>
                <p className='paragraph-indented'>
                  View each individual factor used in the criticality ranking to
                  better understand how each component contributes to the final
                  ranking for each road and bridge.
                </p>
                <h4>
                  <b>Use Limitations</b>
                </h4>
                <p className='paragraph-indented'>
                  The information presented in this tool should be used for
                  screening-level purposes to help inform planning and design
                  efforts. This tool is not intended for regulatory use.
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          className='bg-dark-input'
          onClick={handleInfoModalClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
