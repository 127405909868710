import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useAPIData from '../hooks/useAPIData';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import CustomRadarChart from './CustomRadarChart';
import RoadInfo from './RoadInfo';
import IndividualRoadCriticalityBadge from './IndividualRoadCriticalityBadge';
import MiniMap from './MiniMap';
import html2pdf from 'html2pdf.js';
import { replaceLastInstanceOf, startsWithNoun } from '../utils';
import RoadScores from './RoadScores';

export default function Report() {
  const [searchParams] = useSearchParams();
  const roadID = parseInt(searchParams.get('id'));
  const county = searchParams.get('county');
  const region = searchParams.get('region');
  const road_type = searchParams.get('road_type');
  const road_owner = searchParams.get('road_owner');
  const columns = searchParams.get('columns');

  const info = useAPIData(`/api/individual_road_info?id=${roadID}`)[0];

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetch('/api/individual_road_factors', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: roadID, columns: columns })
    })
      .then((resp) => resp.json())
      .then((data) => {
        let cData = Object.entries(data).map((x) => ({
          column: x[0],
          value: x[1]
        }));
        setChartData(cData);
      });
  }, [roadID, columns]);

  const [crit, setCrit] = useState();

  useEffect(() => {
    fetch('/api/individual_road_criticality', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: roadID,
        county: county,
        region: region,
        road_type: road_type,
        road_owner: road_owner,
        columns: columns
      })
    })
      .then((resp) => resp.json())
      .then((data) => setCrit(data));
  }, [roadID, county, region, road_type, road_owner, columns]);

  let segIs = [];
  ['Coastal Evacuation', 'Multi-Hazard Evacuation', 'Freight'].forEach(
    (col) => {
      if (chartData) {
        const colData = chartData.sort().filter((c) => c.column === col);
        if (colData.length > 0) {
          if (colData[0].value === '1.00') {
            segIs.push(col.toLowerCase() + ' route');
          }
        }
      }
    }
  );

  let segIsNot = [];
  ['Coastal Evacuation', 'Multi-Hazard Evacuation', 'Freight'].forEach(
    (col) => {
      if (chartData) {
        const colData = chartData.filter((c) => c.column === col);
        if (colData.length > 0) {
          if (colData[0].value === '0.00') {
            segIsNot.push(col.toLowerCase() + ' route');
          }
        }
      }
    }
  );

  return (
    <div className='outer-box text-light'>
      <center>
        <Container fluid className='pt-2'>
          <Row>
            <div className='report-head'>
              <Button className='mr-1em' variant='primary' href='/'>
                Home
              </Button>
              <Button
                className='mr-1em'
                variant='warning'
                onClick={() => {
                  html2pdf()
                    .set({
                      filename: `report-${roadID}.pdf`,
                      pagebreak: { mode: 'avoid-all' },
                      image: { type: 'jpeg', quality: 0.99 },
                      html2canvas: {
                        dpi: 192,
                        scale: 4,
                        letterRendering: true,
                        useCORS: true
                      },
                      jsPDF: {
                        unit: 'mm',
                        format: 'a4',
                        orientation: 'portrait'
                      }
                    })
                    .from(document.documentElement)
                    .save();
                }}
              >
                Save as PDF
              </Button>
              <IndividualRoadCriticalityBadge crit={crit} />
            </div>
          </Row>
          <Row>
            {info && (
              <div>
                <h1>Report</h1>
                <h2 className='pb-2'>
                  {info['SLD Name']} from MP {info['Milepost Start']} to MP{' '}
                  {info['Milepost End']}
                </h2>
              </div>
            )}
          </Row>

          <Row>
            <Col>
              <div className='pt-3 pb-3'>
                <h4 className='underlined'>Filters being applied</h4>
                <table>
                  <tbody>
                    <tr>
                      <td className='individual-tbl-header'>
                        <h6>County:</h6>
                      </td>
                      <td className='pl-1em'>
                        <h6>
                          {county === ''
                            ? 'None'
                            : county.replaceAll(',', ', ')}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td className='individual-tbl-header'>
                        <h6>Region:</h6>
                      </td>
                      <td className='pl-1em'>
                        <h6>
                          {region === ''
                            ? 'None'
                            : region.replaceAll(',', ', ')}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td className='individual-tbl-header'>
                        <h6>Road Owner:</h6>
                      </td>
                      <td className='pl-1em'>
                        <h6>
                          {road_owner === ''
                            ? 'None'
                            : road_owner.replaceAll(',', ', ')}
                        </h6>
                      </td>
                    </tr>
                    <tr>
                      <td className='individual-tbl-header'>
                        <h6>Road Type:</h6>
                      </td>
                      <td className='pl-1em'>
                        <h6>
                          {road_type === ''
                            ? 'None'
                            : road_type.replaceAll(',', ', ')}
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <MiniMap
                roadID={roadID}
                county={county}
                region={region}
                road_type={road_type}
                road_owner={road_owner}
                columns={columns}
              />
            </Col>
            <Col>
              <Row>
                {info && crit && chartData && (
                  <>
                    <h3 className='pt-3'>About</h3>
                    <p className='left-justified'>
                      {info['SLD Name']} from milepost {info['Milepost Start']}{' '}
                      to milepost {info['Milepost End']} is located in{' '}
                      {info['County']} county. With an overall criticality score
                      of <b className='underlined'>{crit.Score}</b>, this road
                      segment falls into the{' '}
                      <b className='underlined'>{crit.Criticality}</b> bucket of
                      roads based on the applied filters.
                    </p>
                    {(segIs.length > 0 || segIsNot.length > 0) && (
                      <p className='left-justified'>
                        {segIs.length > 0 && (
                          <>
                            This road segment <b className='underlined'>is</b> a
                            {startsWithNoun(segIs[0]) ? 'n' : ''}{' '}
                            {segIs.length > 1
                              ? replaceLastInstanceOf(
                                  segIs.join(', '),
                                  ', ',
                                  ', and '
                                )
                              : segIs[0]}
                            .
                          </>
                        )}{' '}
                        {segIsNot.length > 0 && (
                          <>
                            This road segment{' '}
                            <b className='underlined'>is not</b> a
                            {startsWithNoun(segIsNot[0]) ? 'n' : ''}{' '}
                            {segIsNot.length > 1
                              ? replaceLastInstanceOf(
                                  segIsNot.join(', '),
                                  ', ',
                                  ', or '
                                )
                              : segIsNot[0]}
                            .
                          </>
                        )}
                      </p>
                    )}
                  </>
                )}
              </Row>
              <Row>
                <Col>
                  <RoadInfo info={info} />
                </Col>
                <Col>
                  <RoadScores chartData={chartData} />
                </Col>
              </Row>
              <Row>
                <div style={{ height: '53vh', paddingTop: '1em' }}>
                  <CustomRadarChart chartData={chartData} />
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </center>
    </div>
  );
}
