import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
  AsyncTypeahead,
  Typeahead,
  Highlighter
} from 'react-bootstrap-typeahead';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import LoadingSpinner from './LoadingSpinner';
import useMap from '../hooks/useMap';
import useAPIData from '../hooks/useAPIData';
import { useMemo, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import {
  renderTooltip,
  handleTooltipMouseEnter,
  handleTooltipMouseLeave
} from '../utils';

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export default function Map({
  county,
  region,
  roadType,
  roadOwner,
  individualFactor,
  columns
}) {
  const [showLegend, setShowLegend] = useState(true);
  const [showBridges, setShowBridges] = useState(false);
  const [showMileposts, setShowMileposts] = useState(false);

  const [activeBasemap, setActiveBasemap] = useState('streets-v11');

  const [showModal, setShowModal] = useState(false);
  const [roadSearch, setRoadSearch] = useState([]);
  const [intersection1Search, setIntersection1Search] = useState([]);
  const [intersection2Search, setIntersection2Search] = useState([]);
  const [roadSearchOptions, setRoadSearchOptions] = useState([]);
  const [isAsyncLoading, setIsAsyncLoading] = useState(false);
  const [isZoomToLoading, setIsZoomToLoading] = useState(false);
  const [rankerSearchResults, setRankerSearchResults] = useState([]);
  const intersectionSearchOptions = useAPIData(
    `/api/search/intersection_options?road=${
      roadSearch[0] ? roadSearch[0].sld_name : undefined
    }&group_id=${
      roadSearch[0] ? roadSearch[0].group_id : undefined
    }&region=${region}&county_name=${county}`
  )[0];
  const mpSearchOptions = useAPIData(
    `/api/search/mp_options?road=${
      roadSearch[0] ? roadSearch[0].sld_name : undefined
    }&group_id=${
      roadSearch[0] ? roadSearch[0].group_id : undefined
    }&region=${region}&county_name=${county}`
  )[0];
  const [mpStart, setMPStart] = useState([]);
  const [mpEnd, setMPEnd] = useState([]);

  const [milepostSearch, setMilepostSearch] = useState(true);

  const roadNumOptions = useAPIData(
    individualFactor
      ? '/api/road_numbers?county_name=&road_type=&road_ownership=&region_name='
      : `/api/road_numbers?county_name=${county}&road_type=${roadType}&road_ownership=${roadOwner}&region_name=${region}`
  )[0];
  const [selectedRoadNum, setSelectedRoadNum] = useState([]);

  const bridgeOptions = useAPIData(
    individualFactor
      ? '/api/bridge_options?county_name=&road_type=&road_ownership=&region_name='
      : `/api/bridge_options?county_name=${county}&road_type=${roadType}&road_ownership=${roadOwner}&region_name=${region}`
  )[0];
  const [showBridgeSearchModal, setShowBridgeSearchModal] = useState(false);
  const [selectedBridge, setSelectedBridge] = useState([]);

  const handleModalClose = () => {
    // keep past search in the modal
    // setSelectedRoadNum('');
    // setRoadSearch([]);
    // setIntersection1Search([]);
    // setIntersection2Search([]);
    // setMPStart([]);
    // setMPEnd([]);
    setRankerSearchResults([]);
    setShowModal(false);
  };

  const handleSearchAgain = () => {
    // keep past search in the modal
    // setSelectedRoadNum('');
    // setRoadSearch([]);
    // setIntersection1Search([]);
    // setIntersection2Search([]);
    // setMPStart([]);
    // setMPEnd([]);
    setRankerSearchResults([]);
  };

  const handleClearSearch = () => {
    setSelectedRoadNum([]);
    setRoadSearch([]);
    setIntersection1Search([]);
    setIntersection2Search([]);
    setMPStart([]);
    setMPEnd([]);
    setRankerSearchResults([]);
  };

  const handleRoadSearch = (query) => {
    setIsAsyncLoading(true);
    fetch(
      `/api/search/road_options?road=${query}&county_name=${
        county ? county : ''
      }&road_type=${roadType ? roadType : ''}&road_ownership=${
        roadOwner ? roadOwner : ''
      }&region_name=${region ? region : ''}&road_number=${
        selectedRoadNum.length > 0 ? selectedRoadNum[0] : ''
      }`
    )
      .then((resp) => resp.json())
      .then((data) => {
        setRoadSearchOptions(data);
        setIsAsyncLoading(false);
      });
  };

  const {
    mbMap,
    handleMapStyleChange,
    handleLayerToggle,
    saveMap,
    highlightFeatures,
    flyMapTo,
    bridgeLayerId
  } = useMap(
    columns,
    county,
    region,
    roadType,
    roadOwner,
    individualFactor,
    showBridges,
    showMileposts,
    activeBasemap
  );

  const legend = useMemo(() => {
    if (
      individualFactor &&
      (individualFactor === 'Serves as a Major Freight Route' ||
        individualFactor === 'Serves as a Coastal Evacuation Route' ||
        individualFactor === 'Serves as a Multi-Hazard Evacuation Route' ||
        individualFactor === 'Ability to Serve Overburdened Communities')
    ) {
      return (
        <div id='legend' className='legend'>
          <h5>{individualFactor}</h5>
          <div>
            <span style={{ backgroundColor: '#5D3A9B' }}></span>True
          </div>
          <div>
            <span style={{ backgroundColor: '#E66100' }}></span>False
          </div>
          <div>
            <br />
            <div>
              <span style={{ border: '1px solid black' }}></span>Bridges
            </div>
            <div>
              <span style={{ backgroundColor: '#13ab0e' }}></span>Mileposts
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div id='legend' className='legend'>
          <h5>{individualFactor ? 'Factor ' : 'Criticality '}Ranking</h5>
          <div>
            <span style={{ backgroundColor: '#DC267F' }}></span>Very High{' '}
            <i>(Top 0-20% of roads)</i>
            {/* <span style={{ 'backgroundColor': 'crimson' }}></span>Very High */}
          </div>
          <div>
            <span style={{ backgroundColor: '#FE6100' }}></span>High{' '}
            <i>(Top 20-40% of roads)</i>
            {/* <span style={{ 'backgroundColor': 'darkorange' }}></span>High */}
          </div>
          <div>
            <span style={{ backgroundColor: '#FFB000' }}></span>Moderate{' '}
            <i>(Top 40-60% of roads)</i>
            {/* <span style={{ 'backgroundColor': 'khaki' }}></span>Moderate */}
          </div>
          <div>
            <span style={{ backgroundColor: '#785EF0' }}></span>Low{' '}
            <i>(Top 60-80% of roads)</i>
            {/* <span style={{ 'backgroundColor': 'darkgreen' }}></span>Low */}
          </div>
          <div>
            <span style={{ backgroundColor: '#648FFF' }}></span>Very Low{' '}
            <i>(Top 80-100% of roads)</i>
            {/* <span style={{ 'backgroundColor': 'limegreen' }}></span>Very Low */}
          </div>
          <div>
            <br />
            <div>
              <span style={{ border: '1px solid black' }}></span>Bridges
            </div>
            <div>
              <span style={{ backgroundColor: '#13ab0e' }}></span>Mileposts
            </div>
          </div>
          <div className='explaination'>
            Rankings are sorted into five equal quintiles. For example, the top
            20% of roads are{' '}
            {individualFactor
              ? 'ranked Very High'
              : 'considered to have Very High criticality'}
            . <br />
            <br /> To see Local roads, please zoom in.
          </div>
        </div>
      );
    }
  }, [individualFactor]);

  const columnNameLookup = {
    'Usage (Average Annual Daily Traffic)': 'aadt_score',
    'Provides Access to NJDOT Maintenance Yard Facilities':
      'access_maintenance_yards_score',
    'Provides Access to Regional Operation Centers':
      'access_regional_op_centers_score',
    'Serves as a Major Freight Route': 'access_freight_routes_score',
    Redundancy: 'redundancy_score',
    'Serves as a Coastal Evacuation Route': 'hurricane_evac_route_score',
    'Serves as a Multi-Hazard Evacuation Route': 'multi_evac_route_score',
    'Provides Access to Major Hospitals and Trauma Centers':
      'access_healthcare_facilities_score',
    'Provides Access to State Police Facilities':
      'access_police_stations_score',
    'Provides Access to Colleges and Universities': 'access_schools_score',
    'Provides Access to Major Employment Centers':
      'major_employment_centers_score',
    'Ability to Serve Overburdened Communities':
      'serve_vulnerable_populations_score'
  };

  return (
    <div>
      <div className='basemap-options'>
        <span>Basemap:</span>
        {['streets-v11', 'dark-v10', 'light-v10', 'satellite-v9'].map(
          (item) => {
            return (
              <OverlayTrigger
                key={item}
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={(props) =>
                  renderTooltip({
                    ...props,
                    id: item.split('-')[0] + '-tooltip',
                    msg: `Switch to the ${item.split('-')[0]} basemap`
                  })
                }
              >
                <ToggleButton
                  size='sm'
                  className='m-2 text-light'
                  key={item}
                  id={item}
                  type='checkbox'
                  variant='outline-secondary'
                  onMouseEnter={handleTooltipMouseEnter}
                  onMouseLeave={handleTooltipMouseLeave}
                  checked={item === activeBasemap}
                  onClick={() => {
                    handleMapStyleChange(item);
                    setActiveBasemap(item);
                  }}
                >
                  {toTitleCase(item.split('-')[0])}
                </ToggleButton>
              </OverlayTrigger>
            );
          }
        )}
        ||
        <ToggleButton
          size='sm'
          className='m-2 text-light'
          id={`show-bridges-check-${Math.random()}`}
          type='checkbox'
          variant='outline-secondary'
          checked={showBridges}
          onChange={() => {
            setShowBridges(!showBridges);
            handleLayerToggle(bridgeLayerId);
          }}
        >
          Bridges
        </ToggleButton>
        <ToggleButton
          size='sm'
          className='m-2 text-light'
          id={`show-mileposts-check-${Math.random()}`}
          type='checkbox'
          variant='outline-secondary'
          checked={showMileposts}
          onChange={() => {
            setShowMileposts(!showMileposts);
            handleLayerToggle('njdot.mileposts');
            handleLayerToggle('njdot.mileposts-labels');
          }}
        >
          Mileposts
        </ToggleButton>
        <ToggleButton
          size='sm'
          className='m-2 text-light'
          id={`show-legend-check-${Math.random()}`}
          type='checkbox'
          variant='outline-secondary'
          checked={showLegend}
          onChange={() => setShowLegend(!showLegend)}
        >
          Legend
        </ToggleButton>
        ||
        <Button
          size='sm'
          className='m-2'
          variant='warning'
          id={`savemap-${Math.random()}`}
          onClick={() => {
            saveMap();
          }}
        >
          Save Map
        </Button>
        <Button
          size='sm'
          className='m-2'
          variant='warning'
          id={`custom-search-${Math.random()}`}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Custom Search
        </Button>
        <Button
          size='sm'
          className='m-2'
          variant='warning'
          id={`bridge-search-${Math.random()}`}
          onClick={() => {
            setShowBridgeSearchModal(true);
          }}
        >
          Bridge Search
        </Button>
      </div>
      <div className='map-wrapper'>
        {showLegend && legend}
        <div
          className='map'
          style={{ height: individualFactor ? '77vh' : '70vh' }}
          ref={mbMap}
          id={`map-${Math.random()}`}
        ></div>
      </div>
      <Modal
        size='lg'
        show={showModal}
        onHide={handleModalClose}
        backdrop='static'
        keyboard={false}
        aria-labelledby='contained-modal-title-vcenter'
        className='text-light'
        centered
      >
        <Modal.Header closeButton={isZoomToLoading ? false : true}>
          <Modal.Title>
            Custom Search&nbsp;&nbsp;&nbsp;&nbsp;
            <BootstrapSwitchButton
              checked={!milepostSearch}
              onlabel='Intersections'
              offlabel='Mileposts'
              width={125}
              onstyle='warning'
              offstyle='info'
              onChange={() => {
                setMilepostSearch(!milepostSearch);
              }}
            />
          </Modal.Title>
        </Modal.Header>

        {rankerSearchResults.length > 0 ? (
          <>
            <Modal.Body>
              <Table
                responsive
                striped
                bordered
                hover
                size='sm'
                variant='dark'
                className='text-light text-small'
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Road Name</th>
                    <th>Type</th>
                    <th>Owner</th>
                    <th>County</th>
                    <th>Milepost Start</th>
                    <th>Milepost End</th>
                    {individualFactor === '' ||
                      (individualFactor === undefined && <th>Score</th>)}
                    <th>
                      {individualFactor === '' ||
                      individualFactor === undefined ||
                      ![
                        'Serves as a Major Freight Route',
                        'Serves as a Coastal Evacuation Route',
                        'Serves as a Multi-Hazard Evacuation Route',
                        'Ability to Serve Overburdened Communities'
                      ].includes(individualFactor)
                        ? 'Criticality'
                        : 'Serves'}
                    </th>
                    {!individualFactor && <th>Report</th>}
                  </tr>
                </thead>
                <tbody>
                  {rankerSearchResults.map((x, i) => {
                    return (
                      <tr key={i}>
                        <td>{x.id}</td>
                        <td>{x.sld_name === '' ? '-' : x.sld_name}</td>
                        <td>{x.funcclass === '' ? '-' : x.funcclass}</td>
                        <td>{x.ownership === '' ? '-' : x.ownership}</td>
                        <td>{x.co_name === '' ? '-' : x.co_name}</td>
                        <td>{x.mp_start}</td>
                        <td>{x.mp_end}</td>
                        {individualFactor === '' ||
                          (individualFactor === undefined && (
                            <td>{x.criticality_score}</td>
                          ))}
                        <td>{x.criticality ? x.criticality : x.serves}</td>
                        {!individualFactor && (
                          <td>
                            <Link
                              to={{
                                pathname: '/report',
                                search:
                                  `?id=${x.id}` +
                                  `&county=${encodeURIComponent(county)}` +
                                  `&road_type=${encodeURIComponent(roadType)}` +
                                  `&road_owner=${encodeURIComponent(
                                    roadOwner
                                  )}` +
                                  `&region=${encodeURIComponent(region)}` +
                                  `&columns=${encodeURIComponent(columns)}`
                              }}
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              View &#x2197;
                            </Link>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer>
              {isZoomToLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Button
                    variant='secondary'
                    className='bg-dark-input'
                    onClick={handleModalClose}
                  >
                    Close
                  </Button>
                  <Button variant='primary' onClick={handleSearchAgain}>
                    Search again
                  </Button>
                </>
              )}
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Body>
              <b>
                Search for and zoom to a road segment by providing a road name
                and two intersections / mileposts.
              </b>
              <Form className='mt-4'>
                {milepostSearch ? (
                  <Form.Group className='mb-3' controlId='ControlInput1'>
                    <Form.Label>
                      Filter by road number{' '}
                      <small>
                        <i>(Optional)</i>
                      </small>
                    </Form.Label>
                    <Typeahead
                      id='dropdown-menu-roadnum-mp'
                      labelKey='int9-name-mp'
                      onChange={setSelectedRoadNum}
                      options={[''].concat(roadNumOptions)}
                      placeholder='Choose a road number...'
                      selected={selectedRoadNum}
                      paginate
                    />
                    <br />
                    <Form.Label>Road Name</Form.Label>
                    <AsyncTypeahead
                      filterBy={() => true}
                      id='async-road-search-mp'
                      isLoading={isAsyncLoading}
                      labelKey={(option) => {
                        return option.sld_name;
                      }}
                      minLength={1}
                      onSearch={handleRoadSearch}
                      onChange={setRoadSearch}
                      options={roadSearchOptions}
                      selected={roadSearch}
                      useCache={false}
                      renderMenuItemChildren={(option, { text }, index) => {
                        return (
                          <div key={index}>
                            <Highlighter search={text}>
                              {option.sld_name}
                            </Highlighter>
                            <div>
                              <small>
                                <Highlighter search={text}>
                                  {option.roadname}
                                </Highlighter>
                                , {option.co_name} County
                                {option.road_num !== '' && (
                                  <>
                                    , Road Number:{' '}
                                    <Highlighter search={text}>
                                      {option.road_num}
                                    </Highlighter>
                                  </>
                                )}
                              </small>
                            </div>
                          </div>
                        );
                      }}
                      placeholder='Search for a road...'
                      paginate
                    />
                    <br />
                    <Form.Label>Milepost Start</Form.Label>
                    <Typeahead
                      id='int3-typeahead-mp'
                      labelKey='int3-name-mp'
                      onChange={setMPStart}
                      options={
                        roadSearch.length > 0 && mpSearchOptions
                          ? mpSearchOptions
                              .slice(0, mpSearchOptions.length - 1)
                              .map(String)
                          : []
                      }
                      placeholder='Choose a starting milepost...'
                      selected={mpStart}
                      paginate
                    />
                    <br />
                    <Form.Label>Milepost End</Form.Label>
                    <Typeahead
                      id='int4-typeahead-mp'
                      className='bg-dark-input text-light'
                      labelKey='int4-name-mp'
                      onChange={setMPEnd}
                      options={
                        roadSearch.length > 0 && mpSearchOptions
                          ? mpSearchOptions
                              .slice(1)
                              .filter((x) => x > mpStart[0])
                              .map(String)
                          : []
                      }
                      placeholder='Choose an ending milepost...'
                      selected={mpEnd}
                      paginate
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className='mb-3' controlId='ControlInput1'>
                    <Form.Label>
                      Filter by road number{' '}
                      <small>
                        <i>(Optional)</i>
                      </small>
                    </Form.Label>
                    <Typeahead
                      id='dropdown-menu-roadnum-intersection'
                      labelKey='int9-name-intersection'
                      onChange={setSelectedRoadNum}
                      options={[''].concat(roadNumOptions)}
                      placeholder='Choose a road number...'
                      selected={selectedRoadNum}
                      paginate
                    />
                    <br />
                    <Form.Label>Road Name</Form.Label>
                    <AsyncTypeahead
                      filterBy={() => true}
                      id='async-road-search'
                      isLoading={isAsyncLoading}
                      labelKey={(option) => {
                        return option.sld_name;
                      }}
                      minLength={1}
                      onSearch={handleRoadSearch}
                      onChange={setRoadSearch}
                      selected={roadSearch}
                      options={roadSearchOptions}
                      useCache={false}
                      renderMenuItemChildren={(option, { text }, index) => {
                        return (
                          <div key={index}>
                            <Highlighter search={text}>
                              {option.sld_name}
                            </Highlighter>
                            <div>
                              <small>
                                <Highlighter search={text}>
                                  {option.roadname}
                                </Highlighter>
                                , {option.co_name} County
                                {option.road_num !== '' && (
                                  <>
                                    , Road Number:{' '}
                                    <Highlighter search={text}>
                                      {option.road_num}
                                    </Highlighter>
                                  </>
                                )}
                              </small>
                            </div>
                          </div>
                        );
                      }}
                      placeholder='Search for a road...'
                      paginate
                    />
                    <br />
                    <Form.Label>Intersection 1</Form.Label>
                    <Typeahead
                      id='int1-typeahead-intersection'
                      labelKey='int1-name-intersection'
                      onChange={setIntersection1Search}
                      options={
                        roadSearch.length > 0 && intersectionSearchOptions
                          ? intersectionSearchOptions.filter(
                              (val) => !intersection2Search.includes(val)
                            )
                          : []
                      }
                      placeholder='Choose an intersection...'
                      selected={intersection1Search}
                      paginate
                    />
                    <br />
                    <Form.Label>Intersection 2</Form.Label>
                    <Typeahead
                      id='int2-typeahead-intersection'
                      className='bg-dark-input text-light'
                      labelKey='int2-name-intersection'
                      onChange={setIntersection2Search}
                      options={
                        roadSearch.length > 0 && intersectionSearchOptions
                          ? intersectionSearchOptions.filter(
                              (val) => !intersection1Search.includes(val)
                            )
                          : []
                      }
                      placeholder='Choose an intersection...'
                      selected={intersection2Search}
                      paginate
                    />
                  </Form.Group>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              {isZoomToLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <Button
                    variant='secondary'
                    className='bg-dark-input'
                    onClick={handleModalClose}
                  >
                    Close
                  </Button>
                  <Button variant='warning' onClick={handleClearSearch}>
                    Clear
                  </Button>
                  <Button
                    disabled={
                      [
                        roadSearch,
                        intersection1Search,
                        intersection2Search
                      ].some((v) => v.length === 0) &&
                      [roadSearch, mpStart, mpEnd].some((v) => v.length === 0)
                    }
                    variant='primary'
                    onClick={async () => {
                      setIsZoomToLoading(true);
                      let reqBody;
                      milepostSearch
                        ? (reqBody =
                            individualFactor === '' ||
                            individualFactor === undefined
                              ? {
                                  road: roadSearch[0].sld_name,
                                  mp_start: parseFloat(mpStart[0]),
                                  mp_end: parseFloat(mpEnd[0]),
                                  group_id: roadSearch[0].group_id
                                }
                              : {
                                  road: roadSearch[0].sld_name,
                                  mp_start: parseFloat(mpStart[0]),
                                  mp_end: parseFloat(mpEnd[0]),
                                  group_id: roadSearch[0].group_id,
                                  single_factor_column_name:
                                    columnNameLookup[individualFactor]
                                })
                        : (reqBody =
                            individualFactor === '' ||
                            individualFactor === undefined
                              ? {
                                  road: roadSearch[0].sld_name,
                                  intersection1: intersection1Search[0],
                                  intersection2: intersection2Search[0],
                                  group_id: roadSearch[0].group_id
                                }
                              : {
                                  road: roadSearch[0].sld_name,
                                  intersection1: intersection1Search[0],
                                  intersection2: intersection2Search[0],
                                  group_id: roadSearch[0].group_id,
                                  single_factor_column_name:
                                    columnNameLookup[individualFactor]
                                });
                      await fetch(
                        milepostSearch
                          ? '/api/search/mileposts'
                          : '/api/search/intersection',
                        {
                          method: 'POST',
                          headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                          },
                          body: JSON.stringify(reqBody)
                        }
                      )
                        .then((resp) => {
                          if (!resp.ok) {
                            setIsZoomToLoading(false);
                            alert('unable to complete search request');
                          }
                          return resp.json();
                        })
                        .then((data) => {
                          highlightFeatures(data.ids);
                          flyMapTo(data.bounds);
                          setRankerSearchResults(data.ranks);
                          setIsZoomToLoading(false);
                        });
                      // handleModalClose();
                    }}
                  >
                    Zoom to
                  </Button>
                </>
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>
      <Modal
        size='lg'
        show={showBridgeSearchModal}
        onHide={() => setShowBridgeSearchModal(false)}
        keyboard={false}
        aria-labelledby='contained-modal-title-vcenter'
        className='text-light'
        centered
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>Bridge Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b>Search for a bridge using its structure number.</b>
          <Form className='mt-4'>
            <Form.Group className='mb-3' controlId='ControlInput1'>
              <Form.Label>Search for bridge structure number</Form.Label>
              <Typeahead
                id='dropdown-menu-bridge-struct'
                labelKey='dropdown-menu-bridge-struct'
                onChange={setSelectedBridge}
                options={[''].concat(bridgeOptions)}
                placeholder='Choose a bridge structure number...'
                selected={selectedBridge}
                paginate
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {isZoomToLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Button
                variant='secondary'
                className='bg-dark-input'
                onClick={() => setShowBridgeSearchModal(false)}
              >
                Close
              </Button>
              <Button variant='warning' onClick={() => setSelectedBridge([])}>
                Clear
              </Button>
              <Button
                disabled={selectedBridge.length === 0}
                variant='primary'
                onClick={async () => {
                  setIsZoomToLoading(true);
                  await fetch(
                    `/api/bridge_bounds?structure_number=${selectedBridge[0]}`
                  )
                    .then((resp) => {
                      if (!resp.ok) {
                        setIsZoomToLoading(false);
                        alert('unable to complete search request');
                      }
                      return resp.json();
                    })
                    .then((data) => {
                      flyMapTo([
                        [data.xmin, data.ymin],
                        [data.xmax, data.ymax]
                      ]);
                      if (showBridges === false) {
                        setShowBridges(true);
                        handleLayerToggle(bridgeLayerId);
                      }
                      setIsZoomToLoading(false);
                    });
                  // handleModalClose();
                }}
              >
                Zoom to
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
