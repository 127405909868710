import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Map from '../components/Map';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function IndividualFactorTab({ tabActive }) {
  const [individualFactorDropDown, setIndividualFactorDropDown] = useState(
    'Usage (Average Annual Daily Traffic)'
  );

  return (
    <Container fluid className='pt-2'>
      <Row className='mt-4 align-items-center text-light '>
        <div>
          <h5 className='factor-label'>Select an individual factor to view:</h5>
          <DropdownButton
            title={individualFactorDropDown}
            id='dropdown-menu-individual-factor'
            variant='warning'
            menuVariant='dark'
            onSelect={(e) => {
              setIndividualFactorDropDown(e);
            }}
          >
            {[
              'Usage (Average Annual Daily Traffic)',
              'Provides Access to NJDOT Maintenance Yard Facilities',
              'Provides Access to Regional Operation Centers',
              'Serves as a Major Freight Route',
              'Redundancy',
              'Serves as a Coastal Evacuation Route',
              'Serves as a Multi-Hazard Evacuation Route',
              'Provides Access to Major Hospitals and Trauma Centers',
              'Provides Access to State Police Facilities',
              'Provides Access to Colleges and Universities',
              'Provides Access to Major Employment Centers',
              'Ability to Serve Overburdened Communities'
            ].map((val) => {
              return (
                <Dropdown.Item as='button' eventKey={val} key={val}>
                  {val}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </div>
      </Row>
      <Row className='mt-4 align-items-center text-light outlined-top'>
        <Col>
          {tabActive && (
            <Map
              county=''
              region=''
              individualFactor={individualFactorDropDown}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}
