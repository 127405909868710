import { useEffect, useState } from 'react';
import MyPagination from './MyPagination';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import LoadingSpinner from './LoadingSpinner';
import { getDateString } from '../utils';
import { Link } from 'react-router-dom';

export default function RanksTableTab({ rankerInfo, defaultItemsPerPage }) {
  const [rowData, setRowData] = useState();
  const [pageNum, setPageNum] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(defaultItemsPerPage);
  const [totalRows, setTotalRows] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const reqBody = {
      county_name: rankerInfo.county_name,
      region_name: rankerInfo.region_name,
      road_type: rankerInfo.road_type,
      road_ownership: rankerInfo.road_ownership,
      column_names: rankerInfo.columns,
      offset: offset,
      limit: limit
    };

    fetch('/api/ranks', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqBody)
    })
      .then((r) => r.json())
      .then((data) => {
        setRowData(data.results);
        setOffset(data.offset);
        setLimit(data.limit);
        setLastPage(data.total_pages);
        setPageNum(data.current_page);
        setTotalRows(data.count);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(
          'Error getting ranks data from POST /api/ranks: ' + error
        );
      });
  }, [offset, limit, rankerInfo]);

  let downloadReqBody = {
    aadt_wt: rankerInfo.aadt_wt,
    hurricane_evac_route_wt: rankerInfo.hurricane_evac_route_wt,
    multi_evac_route_wt: rankerInfo.multi_evac_route_wt,
    access_healthcare_facilities_wt: rankerInfo.access_healthcare_facilities_wt,
    access_police_stations_wt: rankerInfo.access_police_stations_wt,
    access_maintenance_yards_wt: rankerInfo.access_maintenance_yards_wt,
    access_regional_op_centers_wt: rankerInfo.access_regional_op_centers_wt,
    access_freight_routes_wt: rankerInfo.access_freight_routes_wt,
    access_schools_wt: rankerInfo.access_schools_wt,
    major_employment_centers_wt: rankerInfo.major_employment_centers_wt,
    serve_vulnerable_populations_wt: rankerInfo.serve_vulnerable_populations_wt,
    county_name: rankerInfo.county_name,
    region_name: rankerInfo.region_name,
    road_type: rankerInfo.road_type,
    road_ownership: rankerInfo.road_ownership,
    offset: 0,
    limit: 999999999,
    // used for spatial downloads. can be one of [".shp.zip", ".json", ".geojson", ".gpkg"]
    ext: '.shp.zip'
  };

  return (
    <Container fluid className='pt-2'>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <i className='text-light p-2'>Total results: {totalRows}</i>
          <span style={{ 'margin-left': '3em', 'margin-right': '0.5em' }}>
            Save as:
          </span>
          <Button
            className='m-1'
            variant='warning'
            size='sm'
            onClick={() => {
              setIsLoading(true);
              fetch('/api/ranks_csv', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(downloadReqBody)
              })
                .then((response) => response.blob())
                .then((blob) => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `njdot_ranking_results_${getDateString()}.csv`;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                })
                .then(() => setIsLoading(false));
            }}
          >
            CSV
          </Button>
          <Button
            className='m-1'
            variant='warning'
            size='sm'
            onClick={() => {
              setIsLoading(true);
              fetch('/api/geoexport', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...downloadReqBody, ext: '.shp.zip' })
              })
                .then((response) => response.blob())
                .then((blob) => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `njdot_ranking_results_${getDateString()}.shp.zip`;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                })
                .then(() => setIsLoading(false));
            }}
          >
            Shapefile
          </Button>
          <Button
            className='m-1'
            variant='warning'
            size='sm'
            onClick={() => {
              setIsLoading(true);
              fetch('/api/geoexport', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...downloadReqBody, ext: '.geojson' })
              })
                .then((response) => response.blob())
                .then((blob) => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `njdot_ranking_results_${getDateString()}.geojson`;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                })
                .then(() => setIsLoading(false));
            }}
          >
            GeoJSON
          </Button>
          <Button
            className='m-1'
            variant='warning'
            size='sm'
            onClick={() => {
              setIsLoading(true);
              fetch('/api/geoexport', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...downloadReqBody, ext: '.gpkg' })
              })
                .then((response) => response.blob())
                .then((blob) => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `njdot_ranking_results_${getDateString()}.gpkg`;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                })
                .then(() => setIsLoading(false));
            }}
          >
            GeoPackage
          </Button>
          <Button
            className='m-1'
            variant='warning'
            size='sm'
            onClick={() => {
              setIsLoading(true);
              fetch('/api/geoexport', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ ...downloadReqBody, ext: '.gdb' })
              })
                .then((response) => response.blob())
                .then((blob) => {
                  let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  a.href = url;
                  a.download = `njdot_ranking_results_${getDateString()}.gdb.zip`;
                  document.body.appendChild(a);
                  a.click();
                  a.remove();
                })
                .then(() => setIsLoading(false));
            }}
          >
            FileGDB
          </Button>
          <Table
            responsive
            striped
            bordered
            hover
            size='sm'
            variant='dark'
            className='text-light text-small'
          >
            <thead>
              <tr>
                <th>Road Name</th>
                <th>Milepost Start</th>
                <th>Milepost End</th>
                <th>Type</th>
                <th>Owner</th>
                <th>County</th>
                <th>Region</th>
                <th>Criticality Score</th>
                <th>Criticality</th>
                <th>Report</th>
              </tr>
            </thead>
            <tbody>
              {rowData &&
                rowData.map((x, i) => {
                  return (
                    <tr key={i}>
                      <td>{x.sld_name === '' ? '-' : x.sld_name}</td>
                      <td>{x.mp_start}</td>
                      <td>{x.mp_end}</td>
                      <td>{x.funcclass === '' ? '-' : x.funcclass}</td>
                      <td>{x.ownership === '' ? '-' : x.ownership}</td>
                      <td>{x.co_name === '' ? '-' : x.co_name}</td>
                      <td>{x.region === '' ? '-' : x.region}</td>
                      <td>{x.criticality_score}</td>
                      <td>{x.criticality}</td>
                      <td>
                        <Link
                          to={{
                            pathname: '/report',
                            search:
                              `?id=${x.id}` +
                              `&county=${encodeURIComponent(
                                rankerInfo.county_name
                              )}` +
                              `&road_type=${encodeURIComponent(
                                rankerInfo.road_type
                              )}` +
                              `&road_owner=${encodeURIComponent(
                                rankerInfo.road_ownership
                              )}` +
                              `&region=${encodeURIComponent(
                                rankerInfo.region_name
                              )}` +
                              `&columns=${encodeURIComponent(
                                rankerInfo.columns
                              )}`
                          }}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          View &#x2197;
                        </Link>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <MyPagination
            current={pageNum}
            last={lastPage}
            itemsPerPage={limit}
            handItemsPerPageChange={(num) => {
              setLimit(Number(num));
              setOffset(0);
              setPageNum(1);
              setIsLoading(true);
            }}
            handlePageChange={(pg) => {
              setOffset(pg * limit - limit);
              setIsLoading(true);
            }}
          />
        </>
      )}
    </Container>
  );
}
