import Tooltip from 'react-bootstrap/Tooltip';

export function getDateString() {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  let hr = String(today.getHours()).padStart(2, '0');
  let mins = String(today.getMinutes()).padStart(2, '0');
  let sec = String(today.getSeconds()).padStart(2, '0');
  return `${dd}${mm}${yyyy}_${hr}${mins}${sec}`;
}

export const renderTooltip = (props) => {
  const msg = props.msg;
  delete props.msg;
  return <Tooltip {...props}>{msg}</Tooltip>;
};

export const handleTooltipMouseEnter = () => {
  if (
    document.documentElement.scrollHeight <=
    document.documentElement.clientHeight
  ) {
    document.documentElement.style.overflowY = 'hidden';
  }
};

export const handleTooltipMouseLeave = () => {
  document.documentElement.style.overflowY = '';
};

export const replaceLastInstanceOf = (str, toReplace, replacement) => {
  const lastIndex = str.lastIndexOf(toReplace);
  return (
    str.substring(0, lastIndex) + replacement + str.substring(lastIndex + 1)
  );
};

export const startsWithNoun = (str) => {
  return (
    str.startsWith('a') ||
    str.startsWith('e') ||
    str.startsWith('i') ||
    str.startsWith('o') ||
    str.startsWith('u')
  );
};
