import { useState, useRef, useEffect } from 'react';
// see https://docs.mapbox.com/mapbox-gl-js/guides/install/#transpiling
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { getDateString } from '../utils';

export default function useMap(
  columns,
  county,
  region,
  roadType,
  roadOwner,
  individualFactor,
  showBridges,
  showMileposts,
  activeBasemap
) {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const mbMap = useRef();

  const [mapp, setMapp] = useState();
  const [bridgeLayerId, setBridgeLayerId] = useState();

  const handleMapStyleChange = (newStyle) => {
    switchBasemap(mapp, newStyle);
  };

  const handleLayerToggle = (layerID) => {
    const visibility = mapp.getLayoutProperty(layerID, 'visibility');
    if (visibility === 'visible') {
      mapp.setLayoutProperty(layerID, 'visibility', 'none');
    } else {
      mapp.setLayoutProperty(layerID, 'visibility', 'visible');
    }
  };

  const saveMap = () => {
    const dpi = 300;
    Object.defineProperty(window, 'devicePixelRatio', {
      get: function () {
        return dpi / 96;
      }
    });
    mapp.getCanvas().toBlob((blob) => {
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.style.cssText = 'display: none';
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `njdot_criticality_map_${getDateString()}.png`;
      a.click();
      window.URL.revokeObjectURL(url);
      a.parentNode.removeChild(a);
    });
  };

  const highlightFeatures = (ids) => {
    let roadLayerId;
    if (individualFactor) {
      roadLayerId =
        individualFactor === 'Serves as a Major Freight Route' ||
        individualFactor === 'Serves as a Coastal Evacuation Route' ||
        individualFactor === 'Serves as a Multi-Hazard Evacuation Route' ||
        individualFactor === 'Ability to Serve Overburdened Communities'
          ? 'njdot.road_tile_single_factor_binary'
          : 'njdot.road_tile_single_factor';
    } else {
      roadLayerId = 'njdot.road_tile_calcs';
    }

    let paints = [];
    ids.forEach((id) => {
      paints.push(id);
      paints.push(6);
    });

    mapp.setPaintProperty(roadLayerId, 'line-width', [
      'match',
      ['get', 'id'],
      ...paints,
      1
    ]);
  };

  const flyMapTo = (bounds) => {
    mapp.fitBounds(bounds);
  };

  function mapPopUpTable(f, layerID) {
    let h = '';
    let p = f.properties;
    for (let k in p) {
      if (k !== 'seg_id') {
        h += `
      <tr>
        <td style='border: 1px solid #dddddd; text-align: left; padding: 4px;'><b>${k}:</b></td>
        <td style='border: 1px solid #dddddd; text-align: left; padding: 4px;'>${p[k]}</td>
      </tr>`;
      }
    }
    if (layerID.includes('road')) {
      if (!individualFactor) {
        h += `
      <tr>
        <td style='border: 1px solid #dddddd; text-align: left; padding: 4px;'>
          <b>
            <a target='_blank' rel='noopener noreferrer' href='/report?id=${
              p.id
            }&county=${encodeURIComponent(
          county
        )}&road_type=${encodeURIComponent(
          roadType
        )}&road_owner=${encodeURIComponent(
          roadOwner
        )}&region=${encodeURIComponent(region)}&columns=${encodeURIComponent(
          columns
        )}'>
              View Report &#x2197;
            </a>
          </b>
        </td>
      </tr>`;
      } else {
        h += `
      <tr>
        <td style='border: 1px solid #dddddd; text-align: left; padding: 4px;'>
          <b>
            <a target='_blank' rel='noopener noreferrer' href='/report?id=${p.id}&county=&road_type=&road_owner=&region=&columns=aadt_score,access_maintenance_yards_score,access_regional_op_centers_score,access_freight_routes_score,redundancy_score,multi_evac_route_score,hurricane_evac_route_score,access_healthcare_facilities_score,access_police_stations_score,access_schools_score,major_employment_centers_score,serve_vulnerable_populations_score'>
              View Report &#x2197;
            </a>
          </b>
        </td>
      </tr>`;
      }
    }
    return `<table class='text-dark'><tbody>` + h + '</tbody></table>';
  }

  useEffect(() => {
    const run = async () => {
      const columnNameLookup = {
        'Usage (Average Annual Daily Traffic)':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor/{z}/{x}/{y}.pbf?column_name=aadt_score',
        'Provides Access to NJDOT Maintenance Yard Facilities':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor/{z}/{x}/{y}.pbf?column_name=access_maintenance_yards_score',
        'Provides Access to Regional Operation Centers':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor/{z}/{x}/{y}.pbf?column_name=access_regional_op_centers_score',
        'Serves as a Major Freight Route':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor_binary/{z}/{x}/{y}.pbf?column_name=access_freight_routes_score',
        Redundancy:
          window.location.href +
          'tileserver/njdot.road_tile_single_factor/{z}/{x}/{y}.pbf?column_name=redundancy_score',
        'Serves as a Multi-Hazard Evacuation Route':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor_binary/{z}/{x}/{y}.pbf?column_name=multi_evac_route_score',
        'Serves as a Coastal Evacuation Route':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor_binary/{z}/{x}/{y}.pbf?column_name=hurricane_evac_route_score',
        'Provides Access to Major Hospitals and Trauma Centers':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor/{z}/{x}/{y}.pbf?column_name=access_healthcare_facilities_score',
        'Provides Access to State Police Facilities':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor/{z}/{x}/{y}.pbf?column_name=access_police_stations_score',
        'Provides Access to Colleges and Universities':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor/{z}/{x}/{y}.pbf?column_name=access_schools_score',
        'Provides Access to Major Employment Centers':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor/{z}/{x}/{y}.pbf?column_name=major_employment_centers_score',
        'Ability to Serve Overburdened Communities':
          window.location.href +
          'tileserver/njdot.road_tile_single_factor_binary/{z}/{x}/{y}.pbf?column_name=serve_vulnerable_populations_score'
      };

      const bridgeColumnNameLookup = {
        'Usage (Average Annual Daily Traffic)':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor/{z}/{x}/{y}.pbf?column_name=aadt_score',
        'Provides Access to NJDOT Maintenance Yard Facilities':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor/{z}/{x}/{y}.pbf?column_name=access_maintenance_yards_score',
        'Provides Access to Regional Operation Centers':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor/{z}/{x}/{y}.pbf?column_name=access_regional_op_centers_score',
        'Serves as a Major Freight Route':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor_binary/{z}/{x}/{y}.pbf?column_name=access_freight_routes_score',
        Redundancy:
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor/{z}/{x}/{y}.pbf?column_name=redundancy_score',
        'Serves as a Multi-Hazard Evacuation Route':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor_binary/{z}/{x}/{y}.pbf?column_name=multi_evac_route_score',
        'Serves as a Coastal Evacuation Route':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor_binary/{z}/{x}/{y}.pbf?column_name=hurricane_evac_route_score',
        'Provides Access to Major Hospitals and Trauma Centers':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor/{z}/{x}/{y}.pbf?column_name=access_healthcare_facilities_score',
        'Provides Access to State Police Facilities':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor/{z}/{x}/{y}.pbf?column_name=access_police_stations_score',
        'Provides Access to Colleges and Universities':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor/{z}/{x}/{y}.pbf?column_name=access_schools_score',
        'Provides Access to Major Employment Centers':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor/{z}/{x}/{y}.pbf?column_name=major_employment_centers_score',
        'Ability to Serve Overburdened Communities':
          window.location.href +
          'tileserver/njdot.bridge_tile_single_factor_binary/{z}/{x}/{y}.pbf?column_name=serve_vulnerable_populations_score'
      };

      // const roadBounds = await fetch('/api/road_bounds')
      //   .then((r) => r.json())
      //   .catch((error) => {
      //     console.error(
      //       'Error fetching API data from /api/road_bounds: ' + error
      //     );
      //   });

      const roadBounds = {
        xmax: -73.91157517219011,
        xmin: -75.54955805057958,
        ymax: 41.35542152847918,
        ymin: 38.93001944921389
      };

      const myMap = new mapboxgl.Map({
        container: mbMap.current,
        style: 'mapbox://styles/mapbox/' + activeBasemap,
        attributionControl: false,
        bounds: [
          [roadBounds.xmin, roadBounds.ymin],
          [roadBounds.xmax, roadBounds.ymax]
        ],
        preserveDrawingBuffer: true
      });

      if (county !== '' || region !== '') {
        fetch(`/api/map_bounds?counties=${county}&regions=${region}`)
          .then((r) => r.json())
          .then((data) => {
            myMap.fitBounds(
              [
                [data.xmin, data.ymin],
                [data.xmax, data.ymax]
              ],
              { padding: 15 }
            );
          })
          .catch((error) => {
            console.error(
              `Error fetching API data from /api/map_bounds?counties=${county}&regions=${region}: ` +
                error
            );
          });
      }

      myMap.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl
        }),
        'top-right'
      );
      myMap.addControl(new mapboxgl.NavigationControl());

      myMap.on('load', () => {
        // const layers = myMap.getStyle().layers;
        // Find the index of the first symbol layer in the map style.
        let firstSymbolId = 'road-label';
        // for (const layer of layers) {
        //   if (layer.type === 'symbol') {
        //     firstSymbolId = layer.id;
        //     break;
        //   }
        // }

        // console.log(layers);

        if (county !== '') {
          myMap.addSource('njdot.counties', {
            type: 'vector',
            tiles: [
              window.location.href + `tileserver/njdot.counties/{z}/{x}/{y}.pbf`
            ],
            minzoom: 1,
            maxzoom: 20,
            bounds: [
              roadBounds.xmin,
              roadBounds.ymin,
              roadBounds.xmax,
              roadBounds.ymax
            ]
          });

          myMap.addLayer(
            {
              id: 'njdot.counties.fill',
              source: 'njdot.counties',
              'source-layer': 'njdot.counties',
              type: 'fill',
              paint: {
                'fill-color': 'gray',
                'fill-opacity': 0.25
              }
            },
            firstSymbolId
          );

          myMap.setFilter('njdot.counties.fill', [
            'all',
            ['in', 'name', ...county.split(',')]
          ]);
        }
        if (region !== '') {
          myMap.addSource('njdot.regions', {
            type: 'vector',
            tiles: [
              window.location.href + `tileserver/njdot.regions/{z}/{x}/{y}.pbf`
            ],
            minzoom: 1,
            maxzoom: 20,
            bounds: [
              roadBounds.xmin,
              roadBounds.ymin,
              roadBounds.xmax,
              roadBounds.ymax
            ]
          });

          myMap.addLayer(
            {
              id: 'njdot.regions.fill',
              source: 'njdot.regions',
              'source-layer': 'njdot.regions',
              type: 'fill',
              paint: {
                'fill-color': 'gray',
                'fill-opacity': 0.25
              }
            },
            firstSymbolId
          );

          myMap.setFilter('njdot.regions.fill', [
            'all',
            ['in', 'region', ...region.split(',')]
          ]);
        }

        let bridgeLayerId;
        let roadLayerId;
        if (individualFactor) {
          roadLayerId =
            individualFactor === 'Serves as a Major Freight Route' ||
            individualFactor === 'Serves as a Coastal Evacuation Route' ||
            individualFactor === 'Serves as a Multi-Hazard Evacuation Route' ||
            individualFactor === 'Ability to Serve Overburdened Communities'
              ? 'njdot.road_tile_single_factor_binary'
              : 'njdot.road_tile_single_factor';

          myMap.addSource(roadLayerId, {
            type: 'vector',
            tiles: [columnNameLookup[individualFactor]],
            minzoom: 1,
            maxzoom: 20,
            bounds: [
              roadBounds.xmin,
              roadBounds.ymin,
              roadBounds.xmax,
              roadBounds.ymax
            ]
          });

          bridgeLayerId =
            roadLayerId === 'njdot.road_tile_single_factor_binary'
              ? 'njdot.bridge_tile_single_factor_binary'
              : 'njdot.bridge_tile_single_factor';

          myMap.addSource(bridgeLayerId, {
            type: 'vector',
            tiles: [bridgeColumnNameLookup[individualFactor]],
            minzoom: 1,
            maxzoom: 20,
            bounds: [
              roadBounds.xmin,
              roadBounds.ymin,
              roadBounds.xmax,
              roadBounds.ymax
            ]
          });

          let myColor;
          if (
            individualFactor &&
            (individualFactor === 'Serves as a Major Freight Route' ||
              individualFactor === 'Serves as a Coastal Evacuation Route' ||
              individualFactor ===
                'Serves as a Multi-Hazard Evacuation Route' ||
              individualFactor === 'Ability to Serve Overburdened Communities')
          ) {
            myColor = {
              property: 'Serves',
              type: 'categorical',
              stops: [
                ['false', '#E66100'],
                ['true', '#5D3A9B']
              ]
            };
          } else {
            myColor = {
              type: 'categorical',
              property: 'Criticality',
              stops: [
                ['Very Low', '#648FFF'],
                ['Low', '#785EF0'],
                ['Moderate', '#FFB000'],
                ['High', '#FE6100'],
                ['Very High', '#DC267F']
              ]
            };
          }

          myMap.addLayer(
            {
              id: roadLayerId,
              source: roadLayerId,
              'source-layer': 'default',
              type: 'line',
              paint: {
                'line-width': {
                  base: 1,
                  stops: [
                    [9, 2],
                    [22, 5]
                  ]
                },
                'line-color': myColor
              }
            },
            firstSymbolId
          );

          myMap.addLayer(
            {
              id: bridgeLayerId,
              source: bridgeLayerId,
              'source-layer': 'default',
              type: 'circle',
              paint: {
                'circle-radius': {
                  base: 1.5,
                  stops: [
                    [9, 2.5],
                    [22, 100]
                  ]
                },
                'circle-color': myColor,
                'circle-stroke-color': 'black',
                'circle-stroke-width': 1,
                'circle-opacity': 0.5
              },
              layout: {
                visibility: showBridges ? 'visible' : 'none'
              }
            },
            firstSymbolId
          );
        } else {
          roadLayerId = 'njdot.road_tile_calcs';
          myMap.addSource(roadLayerId, {
            type: 'vector',
            tiles: [
              window.location.href +
                `tileserver/njdot.road_tile_calcs/{z}/{x}/{y}.pbf?column_names=${columns}&county=${county}&region=${region}&roadtype=${roadType}&roadownership=${roadOwner}`
            ],
            minzoom: 1,
            maxzoom: 20,
            bounds: [
              roadBounds.xmin,
              roadBounds.ymin,
              roadBounds.xmax,
              roadBounds.ymax
            ]
          });

          bridgeLayerId = 'njdot.bridge_tiles';
          myMap.addSource(bridgeLayerId, {
            type: 'vector',
            tiles: [
              window.location.href +
                `tileserver/njdot.bridge_tiles/{z}/{x}/{y}.pbf?column_names=${columns}&county=${county}&region=${region}&roadtype=${roadType}&roadownership=${roadOwner}`
            ],
            minzoom: 1,
            maxzoom: 20,
            bounds: [
              roadBounds.xmin,
              roadBounds.ymin,
              roadBounds.xmax,
              roadBounds.ymax
            ]
          });

          myMap.addLayer(
            {
              id: roadLayerId,
              source: roadLayerId,
              'source-layer': 'default',
              type: 'line',
              paint: {
                'line-width': {
                  base: 1,
                  stops: [
                    [9, 2],
                    [22, 5]
                  ]
                },
                'line-color': {
                  type: 'categorical',
                  property: 'Criticality',
                  stops: [
                    ['Very Low', '#648FFF'],
                    ['Low', '#785EF0'],
                    ['Moderate', '#FFB000'],
                    ['High', '#FE6100'],
                    ['Very High', '#DC267F']
                  ]
                }
              }
            },
            firstSymbolId
          );

          myMap.addLayer(
            {
              id: bridgeLayerId,
              source: bridgeLayerId,
              'source-layer': 'default',
              type: 'circle',
              paint: {
                'circle-radius': {
                  base: 1.5,
                  stops: [
                    [9, 2.5],
                    [22, 100]
                  ]
                },
                'circle-color': {
                  type: 'categorical',
                  property: 'Criticality',
                  stops: [
                    ['Very Low', '#648FFF'],
                    ['Low', '#785EF0'],
                    ['Moderate', '#FFB000'],
                    ['High', '#FE6100'],
                    ['Very High', '#DC267F']
                  ]
                },
                'circle-stroke-color': 'black',
                'circle-stroke-width': 1,
                'circle-opacity': 0.5
              },
              layout: {
                visibility: showBridges ? 'visible' : 'none'
              }
            },
            firstSymbolId
          );
        }

        if (county !== '') {
          myMap.addLayer(
            {
              id: 'njdot.counties.outline',
              source: 'njdot.counties',
              'source-layer': 'njdot.counties',
              type: 'line',
              paint: {
                'line-width': {
                  base: 2,
                  stops: [
                    [9, 2],
                    [22, 5]
                  ]
                },
                'line-color': 'black'
              }
            },
            firstSymbolId
          );
          myMap.setFilter('njdot.counties.outline', [
            'all',
            ['in', 'name', ...county.split(',')]
          ]);
        }

        if (region !== '') {
          myMap.addLayer(
            {
              id: 'njdot.regions.outline',
              source: 'njdot.regions',
              'source-layer': 'njdot.regions',
              type: 'line',
              paint: {
                'line-width': {
                  base: 2,
                  stops: [
                    [9, 2],
                    [22, 5]
                  ]
                },
                'line-color': 'black'
              }
            },
            firstSymbolId
          );
          myMap.setFilter('njdot.regions.outline', [
            'all',
            ['in', 'region', ...region.split(',')]
          ]);
        }

        myMap.addSource('njdot.mileposts', {
          type: 'vector',
          tiles: [
            window.location.href + 'tileserver/njdot.mileposts/{z}/{x}/{y}.pbf'
          ],
          minzoom: 1,
          maxzoom: 20,
          bounds: [
            roadBounds.xmin,
            roadBounds.ymin,
            roadBounds.xmax,
            roadBounds.ymax
          ]
        });

        myMap.addLayer(
          {
            id: 'njdot.mileposts',
            source: 'njdot.mileposts',
            'source-layer': 'njdot.mileposts',
            type: 'circle',
            paint: {
              'circle-radius': {
                base: 1.5,
                stops: [
                  [9, 2],
                  [15, 6],
                  [22, 100]
                ]
              },
              'circle-color': '#13ab0e'
            },
            layout: {
              visibility: showMileposts ? 'visible' : 'none'
            }
          },
          firstSymbolId
        );

        myMap.addLayer(
          {
            id: 'njdot.mileposts-labels',
            source: 'njdot.mileposts',
            'source-layer': 'njdot.mileposts',
            type: 'symbol',
            minzoom: 12,
            layout: {
              visibility: showMileposts ? 'visible' : 'none',
              'text-field': ['get', 'mp'],
              'text-variable-anchor': ['right'],
              'text-radial-offset': 0.5,
              'text-justify': 'auto'
            },
            paint: {
              'text-halo-color': 'white',
              'text-halo-width': 3
            }
          },
          firstSymbolId
        );

        [bridgeLayerId, roadLayerId, 'njdot.mileposts'].forEach((layerId) => {
          myMap.on('click', layerId, (e) => {
            let f = myMap.queryRenderedFeatures(e.point, {
              layers: [bridgeLayerId, roadLayerId, 'njdot.mileposts']
            });
            if (f.length) {
              if (f[0].layer.id === layerId) {
                new mapboxgl.Popup()
                  .setLngLat(e.lngLat)
                  .setHTML(mapPopUpTable(e.features[0], layerId))
                  .addTo(myMap);
                return;
              }
            }
            return;
          });

          myMap.on('mouseenter', layerId, () => {
            myMap.getCanvas().style.cursor = 'pointer';
          });

          myMap.on('mouseleave', layerId, () => {
            myMap.getCanvas().style.cursor = '';
          });

          setBridgeLayerId(bridgeLayerId);
        });
      });

      setMapp(myMap);

      return () => myMap.remove();
    };

    run();
    // eslint-disable-next-line
  }, [county, region, roadType, roadOwner, individualFactor, columns]);

  return {
    mbMap,
    handleMapStyleChange,
    handleLayerToggle,
    saveMap,
    highlightFeatures,
    flyMapTo,
    bridgeLayerId
  };
}

async function switchBasemap(map, styleID) {
  const newStyle = await fetch(
    `https://api.mapbox.com/styles/v1/mapbox/${styleID}?access_token=${mapboxgl.accessToken}`
  ).then((r) => r.json());
  const currentStyle = map.getStyle();
  // ensure any sources from the current style are copied across to the new style
  newStyle.sources = Object.assign({}, currentStyle.sources, newStyle.sources);

  // find the index of where to insert our layers to retain in the new style
  let labelIndex = newStyle.layers.findIndex((el) => {
    return el.id === 'waterway-label';
  });

  // default to on top
  if (labelIndex === -1) {
    labelIndex = newStyle.layers.length;
  }
  const appLayers = currentStyle.layers.filter((el) => {
    // app layers are the layers to retain, and these are any layers which have a different source set
    return (
      el.source &&
      el.source !== 'mapbox://mapbox.satellite' &&
      el.source !== 'mapbox' &&
      el.source !== 'composite'
    );
  });
  newStyle.layers = [
    ...newStyle.layers.slice(0, labelIndex),
    ...appLayers,
    ...newStyle.layers.slice(labelIndex, -1)
  ];
  map.setStyle(newStyle);
}
