import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Map from './Map';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import useAPIData from '../hooks/useAPIData';
import RanksTableTab from './RanksTableTab';
import IndividualFactorTab from './IndividualFactorTab';
import CompareScenariosTab from './CompareScenariosTab';
import InfoModal from './InfoModal';
import SaveScenarioModal from './SaveScenarioModal';
import {
  renderTooltip,
  handleTooltipMouseEnter,
  handleTooltipMouseLeave
} from '../utils';
import njdot_icon from '../images/njdot_icon.png';
import MultiDropDown from './MultiDropDown';

export default function MainPage() {
  const [county, setCounty] = useState('');
  const [roadType, setRoadType] = useState('');
  const [roadOwner, setRoadOwner] = useState('');
  const [region, setRegion] = useState('');

  const [selectedCounties, setSelectedCounties] = useState([]);
  const [selectedRoadTypes, setSelectedRoadtypes] = useState([]);
  const [selectedRoadOwners, setSelectedRoadOwners] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);

  const [aadtChecked, setAadtChecked] = useState(true);
  const [maintYardChecked, setMaintYardChecked] = useState(true);
  const [rocChecked, setRocChecked] = useState(true);
  const [freightRtChecked, setFreightRtChecked] = useState(true);
  const [redundancyChecked, setRedundancyChecked] = useState(true);
  const [evacRtChecked, setEvacRtChecked] = useState(true);
  const [coastalEvacRtChecked, setCoastalEvacRtChecked] = useState(true);
  const [hosptialChecked, setHosptialChecked] = useState(true);
  const [policeChecked, setPoliceChecked] = useState(true);
  const [collegeChecked, setCollegeChecked] = useState(true);
  const [mecChecked, setMecChecked] = useState(true);
  const [overburdenedChecked, setOverburdenedChecked] = useState(true);

  const [highlightRecalcButton, setHighlightRecalcButton] = useState(false);

  const [columns, setColumns] = useState(
    'aadt_score,access_maintenance_yards_score,access_regional_op_centers_score,access_freight_routes_score,redundancy_score,multi_evac_route_score,hurricane_evac_route_score,access_healthcare_facilities_score,access_police_stations_score,access_schools_score,major_employment_centers_score,serve_vulnerable_populations_score'
  );
  const [showModal, setShowModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(true);

  const handleInfoModalClose = () => {
    setShowInfoModal(false);
  };

  const setAllValues = () => {
    setCounty(selectedCounties.join(','));
    setRegion(selectedRegions.join(','));
    setRoadType(selectedRoadTypes.join(','));
    setRoadOwner(selectedRoadOwners.join(','));
    let cols = [];
    [
      [aadtChecked, 'aadt_score'],
      [maintYardChecked, 'access_maintenance_yards_score'],
      [rocChecked, 'access_regional_op_centers_score'],
      [freightRtChecked, 'access_freight_routes_score'],
      [redundancyChecked, 'redundancy_score'],
      [evacRtChecked, 'multi_evac_route_score'],
      [coastalEvacRtChecked, 'hurricane_evac_route_score'],
      [hosptialChecked, 'access_healthcare_facilities_score'],
      [policeChecked, 'access_police_stations_score'],
      [collegeChecked, 'access_schools_score'],
      [mecChecked, 'major_employment_centers_score'],
      [overburdenedChecked, 'serve_vulnerable_populations_score']
    ].forEach((a) => {
      if (a[0]) {
        cols.push(a[1]);
      }
    });
    setColumns(cols.join(','));
  };

  const counties = useAPIData('/api/county_names')[0];
  const roadOwners = useAPIData('/api/road_owners')[0];
  const roadTypes = useAPIData('/api/road_types')[0];
  const regions = useAPIData('/api/region_names')[0];

  const [scenarios, scenariosRefresher] = useAPIData('/api/scenarios');

  const enforceCheckBoxes = () => {
    return [
      aadtChecked,
      maintYardChecked,
      rocChecked,
      freightRtChecked,
      redundancyChecked,
      evacRtChecked,
      coastalEvacRtChecked,
      hosptialChecked,
      policeChecked,
      collegeChecked,
      mecChecked,
      overburdenedChecked
    ].every((x) => x === false);
  };

  const [createTabActive, setCreateTabActive] = useState(true);
  const [compareTabActive, setCompareTabActive] = useState(false);
  const [viewTabActive, setViewTabActive] = useState(false);

  const [showMap, setShowMap] = useState(true);
  const [showTable, setShowTable] = useState(false);

  return (
    <div className='show-scroll'>
      <div className='outer-box'>
        <div className='outlined'>
          <span
            className='info-usage-btn'
            onClick={() => {
              setShowInfoModal(true);
            }}
          >
            &#x1F6C8; Information / Usage
          </span>
          <InfoModal
            showInfoModal={showInfoModal}
            handleInfoModalClose={handleInfoModalClose}
          />
          <Tabs
            defaultActiveKey='create'
            id='uncontrolled-tabs'
            className='p-1 text-light'
            onClick={(e) => {
              if (e.target.textContent === 'Create a scenario') {
                setCreateTabActive(true);
                setCompareTabActive(false);
                setViewTabActive(false);
              } else if (e.target.textContent === 'Compare scenarios') {
                setCreateTabActive(false);
                setCompareTabActive(true);
                setViewTabActive(false);
              } else {
                setCreateTabActive(false);
                setCompareTabActive(false);
                setViewTabActive(true);
              }
            }}
          >
            <Tab eventKey='create' title='Create a scenario'>
              <Container fluid className='pt-2'>
                <Row className='align-items-center text-light mt-3'>
                  <Col xs={6} md={4}>
                    <h3 className='header-title'>
                      <img
                        src={njdot_icon}
                        height='50px'
                        className='logo'
                        alt='NJDOT icon'
                      />
                      NJDOT Criticality Assessment Tool
                    </h3>
                  </Col>
                  <Col>
                    <div className='dropdown-label'>Filter by region:</div>
                    <MultiDropDown
                      options={regions}
                      onSelectFunction={() => setHighlightRecalcButton(true)}
                      id='dropdown-menu-region'
                      itemIDPrefix='dropdown-menu-region'
                      selected={selectedRegions}
                      setSelected={setSelectedRegions}
                    />
                  </Col>
                  <Col>
                    <div className='dropdown-label'>Filter by county:</div>
                    <MultiDropDown
                      options={counties}
                      onSelectFunction={() => setHighlightRecalcButton(true)}
                      id='dropdown-menu-county'
                      itemIDPrefix='dropdown-menu-county'
                      selected={selectedCounties}
                      setSelected={setSelectedCounties}
                    />
                  </Col>
                  <Col>
                    <div className='dropdown-label'>Filter by road type:</div>
                    <MultiDropDown
                      options={roadTypes}
                      onSelectFunction={() => setHighlightRecalcButton(true)}
                      id='dropdown-menu-roadtype'
                      itemIDPrefix='dropdown-menu-roadtype'
                      selected={selectedRoadTypes}
                      setSelected={setSelectedRoadtypes}
                    />
                  </Col>
                  <Col>
                    <div className='dropdown-label'>
                      Filter by road ownership:
                    </div>
                    <DropdownButton
                      size='sm'
                      title={
                        selectedRoadOwners.length === 0
                          ? 'All'
                          : selectedRoadOwners[0]
                      }
                      id='dropdown-menu-roadowner'
                      variant='warning'
                      menuVariant='dark'
                      onSelect={(e) => {
                        if (e === '') {
                          setSelectedRoadOwners([]);
                        } else {
                          setSelectedRoadOwners([e]);
                        }

                        setHighlightRecalcButton(true);
                      }}
                    >
                      {[''].concat(roadOwners).map((val, i) => {
                        return (
                          <Dropdown.Item eventKey={val} key={val + i}>
                            {val === '' ? 'All' : val}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </Col>
                </Row>
                <Row className='mt-4 align-items-start text-light outlined-top'>
                  <Col xs={12} md={8}>
                    <div className='pt-2'>
                      <ToggleButton
                        size='sm'
                        className='m-2 text-light'
                        id={`show-map-check-${Math.random()}`}
                        type='checkbox'
                        variant='outline-primary'
                        checked={showMap}
                        onClick={() => {
                          setShowMap(!showMap);
                          setShowTable(!showTable);
                        }}
                      >
                        Map View
                      </ToggleButton>
                      <ToggleButton
                        size='sm'
                        className='m-2 text-light'
                        id={`show-table-check-${Math.random()}`}
                        type='checkbox'
                        variant='outline-primary'
                        checked={showTable}
                        onClick={() => {
                          setShowMap(!showMap);
                          setShowTable(!showTable);
                        }}
                      >
                        Table View
                      </ToggleButton>
                    </div>
                    {showMap && createTabActive && (
                      <Map
                        county={county}
                        region={region}
                        roadType={roadType}
                        roadOwner={roadOwner}
                        columns={columns}
                      />
                    )}
                    {showTable && createTabActive && (
                      <RanksTableTab
                        rankerInfo={{
                          county_name: county,
                          region_name: region,
                          road_type: roadType,
                          road_ownership: roadOwner,
                          columns: columns
                        }}
                        defaultItemsPerPage={20}
                      />
                    )}
                  </Col>
                  <Col xs={6} md={4}>
                    <Container className='mt-2'>
                      <Row>
                        <h4>
                          Select factors to include in the ranking calculation:
                        </h4>
                      </Row>
                      <Row className='mt-1'>
                        <h5>Capacity, Maintenance & Operations</h5>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'aadt-tooltip',
                              msg: 'Source: HPMS (2021), supplemented by NJDOT Flow Layer (2020)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='aadt-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={aadtChecked}
                            onChange={(e) => {
                              setAadtChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Usage (Average Annual Daily Traffic)
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'maint-yard-tooltip',
                              msg: 'Source: NJDOT (2021)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='maint-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={maintYardChecked}
                            onChange={(e) => {
                              setMaintYardChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Provides Access to NJDOT Maintenance Yard Facilities
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'roc-tooltip',
                              msg: 'Source: NJDOT (2021)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='roc-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={rocChecked}
                            onChange={(e) => {
                              setRocChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Provides Access to Regional Operation Centers
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'freight-tooltip',
                              msg: 'Source: NJDOT (2021)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='freight-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={freightRtChecked}
                            onChange={(e) => {
                              setFreightRtChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Serves as a Major Freight Route
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'redundancy-tooltip',
                              msg: 'Source: Dewberry (2022)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='redundancy-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={redundancyChecked}
                            onChange={(e) => {
                              setRedundancyChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Redundancy
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row className='mt-1'>
                        <h5>Safety & Resilience</h5>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'coastal-evac-tooltip',
                              msg: 'Source: HIFLD (2022)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='coastal-evac-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={coastalEvacRtChecked}
                            onChange={(e) => {
                              setCoastalEvacRtChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Serves as a Coastal Evacuation Route
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'evac-tooltip',
                              msg: 'Source: Rutgers (2017)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='evac-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={evacRtChecked}
                            onChange={(e) => {
                              setEvacRtChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Serves as a Multi-Hazard Evacuation Route
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'hosp-tooltip',
                              msg: 'Source: NJDOH (2021)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='hospital-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={hosptialChecked}
                            onChange={(e) => {
                              setHosptialChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Provides Access to Major Hospitals and Trauma
                            Centers
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'police-tooltip',
                              msg: 'Source: NJDOT (2021)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='police-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={policeChecked}
                            onChange={(e) => {
                              setPoliceChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Provides Access to State Police Facilities
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row className='mt-1'>
                        <h5>Socioeconomic</h5>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'college-tooltip',
                              msg: 'Source: NJGIN (2021)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='college-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={collegeChecked}
                            onChange={(e) => {
                              setCollegeChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Provides Access to Colleges and Universities
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'employ-tooltip',
                              msg: 'Source: USHUD (2022)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='mec-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={mecChecked}
                            onChange={(e) => {
                              setMecChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Provides Access to Major Employment Centers
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row>
                        <OverlayTrigger
                          placement='top'
                          delay={{ show: 250, hide: 400 }}
                          overlay={(props) =>
                            renderTooltip({
                              ...props,
                              id: 'overburdened-tooltip',
                              msg: 'Source: NJDEP (2022)'
                            })
                          }
                        >
                          <ToggleButton
                            className='mb-2 text-light'
                            id='overburdened-check'
                            type='checkbox'
                            variant='outline-primary'
                            checked={overburdenedChecked}
                            onChange={(e) => {
                              setOverburdenedChecked(e.currentTarget.checked);
                              setHighlightRecalcButton(true);
                            }}
                            onMouseEnter={handleTooltipMouseEnter}
                            onMouseLeave={handleTooltipMouseLeave}
                          >
                            Ability to Serve Overburdened Communities
                          </ToggleButton>
                        </OverlayTrigger>
                      </Row>
                      <Row className='mt-4 justify-content-md-evenly'>
                        <Col md='auto'>
                          <OverlayTrigger
                            placement='top'
                            delay={{ show: 250, hide: 400 }}
                            overlay={(props) =>
                              renderTooltip({
                                ...props,
                                id: 'recalc-tooltip',
                                msg: 'Recalculate the criticality rankings, causing the map to rerender and the underlying data to refresh'
                              })
                            }
                          >
                            <Button
                              variant='outline-warning'
                              style={
                                highlightRecalcButton
                                  ? {
                                      boxShadow: '0 0 4px 4px var(--bs-yellow)'
                                    }
                                  : {}
                              }
                              onClick={() => {
                                if (enforceCheckBoxes()) {
                                  alert(
                                    'At least one factor to include in ranking must be selected'
                                  );
                                } else {
                                  setAllValues();
                                  setHighlightRecalcButton(false);
                                }
                              }}
                              onMouseEnter={handleTooltipMouseEnter}
                              onMouseLeave={handleTooltipMouseLeave}
                            >
                              [Re]Calculate Ranks
                            </Button>
                          </OverlayTrigger>
                        </Col>
                        <Col md='auto'>
                          <OverlayTrigger
                            placement='top'
                            delay={{ show: 250, hide: 400 }}
                            overlay={(props) =>
                              renderTooltip({
                                ...props,
                                id: 'save-scen-tooltip',
                                msg: 'Saves the current scenario, making it accessible in the compare tab'
                              })
                            }
                          >
                            <Button
                              variant='outline-warning'
                              onClick={() => {
                                if (enforceCheckBoxes()) {
                                  alert(
                                    'At least one factor to include in ranking must be selected'
                                  );
                                } else {
                                  setShowModal(true);
                                }
                              }}
                              onMouseEnter={handleTooltipMouseEnter}
                              onMouseLeave={handleTooltipMouseLeave}
                            >
                              Save Scenario
                            </Button>
                          </OverlayTrigger>
                          <SaveScenarioModal
                            showModal={showModal}
                            columns={columns}
                            countyDropDown={selectedCounties.join(',')}
                            roadTypeDropDown={selectedRoadTypes.join(',')}
                            roadOwnerDropDown={selectedRoadOwners.join(',')}
                            regionDropDown={selectedRegions.join(',')}
                            scenariosRefresher={scenariosRefresher}
                            setAllValues={setAllValues}
                            setShowModal={setShowModal}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </Tab>
            <Tab eventKey='compare' title='Compare scenarios'>
              <CompareScenariosTab
                tabActive={compareTabActive}
                scenarios={scenarios}
              />
            </Tab>
            <Tab eventKey='view' title='View individual factors'>
              <IndividualFactorTab tabActive={viewTabActive} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
