export default function IndividualRoadCriticalityBadge({ crit }) {
  return (
    <div
      className='individual-crit-badge'
      style={{
        color: crit
          ? crit.Criticality === 'Very High'
            ? '#DC267F'
            : crit.Criticality === 'High'
            ? '#FE6100'
            : crit.Criticality === 'Moderate'
            ? '#FFB000'
            : crit.Criticality === 'Low'
            ? '#785EF0'
            : crit.Criticality === 'Very Low'
            ? '#648FFF'
            : undefined
          : undefined
      }}
    >
      <div>{crit ? crit.Criticality : 'Loading'}</div>
      <div>{crit ? crit.Score : 'criticality...'}</div>
    </div>
  );
}
