import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

export default function SaveScenarioModal({
  showModal,
  columns,
  countyDropDown,
  roadTypeDropDown,
  roadOwnerDropDown,
  regionDropDown,
  scenariosRefresher,
  setAllValues,
  setShowModal
}) {
  const [scenarioName, setScenarioName] = useState('');
  const [scenarioDescription, setScenarioDescription] = useState('');

  const handleModalClose = () => {
    setScenarioName('');
    setScenarioDescription('');
    setShowModal(false);
  };

  return (
    <Modal
      size='lg'
      show={showModal}
      onHide={handleModalClose}
      backdrop='static'
      keyboard={false}
      aria-labelledby='contained-modal-title-vcenter'
      className='text-light'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Save Scenario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>Please provide a name and description for your scenario</b>
        <Form className='mt-4'>
          <Form.Group className='mb-3' controlId='ControlInput1'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='input'
              className='bg-dark-input text-light'
              onChange={(e) => setScenarioName(e.target.value)}
            />
            <Form.Text className='text-muted'>
              <i>
                If you name your scenario the same as one that already exists, a
                "1" will be appended to it.
              </i>
            </Form.Text>
          </Form.Group>
          <Form.Group className='mb-3' controlId='ControlTextarea1'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as='textarea'
              rows={3}
              className='bg-dark-input text-light'
              onChange={(e) => setScenarioDescription(e.target.value)}
            />
            <Form.Text className='text-muted'>
              <i>This field is optional.</i>
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          className='bg-dark-input'
          onClick={handleModalClose}
        >
          Close
        </Button>
        <Button
          variant='primary'
          onClick={() => {
            let re = /^\w+$/;
            if (!re.test(scenarioName)) {
              alert(
                'Scenario names cannot be empty and must only include letters, numbers and underscores.'
              );
            } else {
              // make the post request
              let reqBody = {
                name: scenarioName,
                description: scenarioDescription,
                columns: columns,
                county_name: countyDropDown,
                road_type: roadTypeDropDown,
                road_ownership: roadOwnerDropDown,
                region: regionDropDown
              };
              fetch('/api/scenarios', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(reqBody)
              })
                .then((r) => r.json())
                .then((data) => console.log(data))
                .catch((error) => {
                  console.error(
                    'Error POSTing scenario data to /api/scenarios: ' + error
                  );
                });
              setAllValues();
              scenariosRefresher();
              handleModalClose();
            }
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
