import { useMemo, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import MyPagination from './MyPagination';
import RanksTableTab from './RanksTableTab';
import Map from './Map';

export default function CompareScenariosTab({ tabActive, scenarios }) {
  // allow for filtering of the scenarios table
  const [nameFilter, setNameFilter] = useState('');
  const [descriptionFilter, setDescriptionFilter] = useState('');
  const [countyFilter, setCountyFilter] = useState('');
  const [roadTypeFilter, setRoadTypeFilter] = useState('');
  const [roadOwnerFilter, setRoadOwnerFilter] = useState('');
  const [regionFilter, setRegionFilter] = useState('');

  const [scenarioPageNumber, setScenarioPageNumber] = useState(1);
  const [numScenariosPerPage, setNumScenariosPerPage] = useState(10);

  const filterScenarios = (s, currentPage, itemsPerPage) => {
    const filtered = s.filter((x) => {
      if (!String(x.Name).toLowerCase().includes(nameFilter.toLowerCase())) {
        return false;
      }
      if (
        !String(x.Description)
          .toLowerCase()
          .includes(descriptionFilter.toLowerCase())
      ) {
        return false;
      }
      if (
        !String(x.CountyName).toLowerCase().includes(countyFilter.toLowerCase())
      ) {
        return false;
      }
      if (
        !String(x.Region).toLowerCase().includes(regionFilter.toLowerCase())
      ) {
        return false;
      }
      if (
        !String(x.RoadType).toLowerCase().includes(roadTypeFilter.toLowerCase())
      ) {
        return false;
      }
      if (
        !String(x.RoadOwnership)
          .toLowerCase()
          .includes(roadOwnerFilter.toLowerCase())
      ) {
        return false;
      }
      return true;
    });

    const start = (currentPage - 1) * itemsPerPage;
    const stop = Math.min(currentPage * itemsPerPage, filtered.length);

    return {
      data: filtered.slice(start, stop),
      lastPage: Math.ceil(filtered.length / numScenariosPerPage)
    };
  };

  const filteredScenarios = useMemo(() => {
    if (scenarios) {
      return filterScenarios(
        scenarios,
        scenarioPageNumber,
        numScenariosPerPage
      );
    } else {
      return {
        data: [],
        lastPage: 1
      };
    }
    // eslint-disable-next-line
  }, [
    scenarios,
    scenarioPageNumber,
    numScenariosPerPage,
    nameFilter,
    countyFilter,
    roadTypeFilter,
    roadOwnerFilter,
    filterScenarios
  ]);

  const [refreshCompareMaps, setRefreshCompareMaps] = useState(1);
  const [selectedScenarios, setSelectedScenarios] = useState([]);
  const handleScenarioAddition = (x) => {
    if (selectedScenarios.length < 3) {
      setSelectedScenarios(selectedScenarios.concat([x]));
      setRefreshCompareMaps(refreshCompareMaps + 1);
      return true;
    } else {
      alert('Only three scenarios can be compared at once.');
      return false;
    }
  };
  const handleScenarioRemoval = (x) => {
    const scens = selectedScenarios;
    const idx = scens.indexOf(x);
    if (idx > -1) {
      // only splice array when item is found
      scens.splice(idx, 1); // 2nd parameter means remove one item only
    }
    setSelectedScenarios(scens);
    setRefreshCompareMaps(refreshCompareMaps + 1);
  };

  return (
    <Container fluid className='pt-2'>
      <Row className='text-light pb-07'>
        <Col>
          <h3>Scenarios for Comparison</h3>
          <div className='m-3'>
            Select up to 3 scenarios below to compare them.
          </div>
          <Table
            responsive
            striped
            bordered
            hover
            size='sm'
            variant='dark'
            className='text-light text-small'
          >
            <thead>
              <tr>
                <th></th>
                <th>
                  Name
                  <Form.Control
                    className='bg-dark-input text-light'
                    type='input'
                    placeholder=''
                    size='sm'
                    value={nameFilter}
                    onChange={(e) => {
                      setNameFilter(e.target.value);
                      setScenarioPageNumber(1);
                    }}
                  />
                </th>
                <th>
                  Description
                  <Form.Control
                    className='bg-dark-input text-light'
                    type='input'
                    placeholder=''
                    size='sm'
                    value={descriptionFilter}
                    onChange={(e) => {
                      setDescriptionFilter(e.target.value);
                      setScenarioPageNumber(1);
                    }}
                  />
                </th>
                <th>
                  County
                  <Form.Control
                    className='bg-dark-input text-light'
                    type='input'
                    placeholder=''
                    size='sm'
                    value={countyFilter}
                    onChange={(e) => {
                      setCountyFilter(e.target.value);
                      setScenarioPageNumber(1);
                    }}
                  />
                </th>
                <th>
                  Region
                  <Form.Control
                    className='bg-dark-input text-light'
                    type='input'
                    placeholder=''
                    size='sm'
                    value={regionFilter}
                    onChange={(e) => {
                      setRegionFilter(e.target.value);
                      setScenarioPageNumber(1);
                    }}
                  />
                </th>
                <th>
                  Type
                  <Form.Control
                    className='bg-dark-input text-light'
                    type='input'
                    placeholder=''
                    size='sm'
                    value={roadTypeFilter}
                    onChange={(e) => {
                      setRoadTypeFilter(e.target.value);
                      setScenarioPageNumber(1);
                    }}
                  />
                </th>
                <th>
                  Owner
                  <Form.Control
                    className='bg-dark-input text-light'
                    type='input'
                    placeholder=''
                    size='sm'
                    value={roadOwnerFilter}
                    onChange={(e) => {
                      setRoadOwnerFilter(e.target.value);
                      setScenarioPageNumber(1);
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {scenarios &&
                filteredScenarios.data.map((x, i) => {
                  return (
                    <TableRow
                      data={x}
                      myKey={i}
                      key={i}
                      handleCheckAddition={handleScenarioAddition}
                      handleCheckRemoval={handleScenarioRemoval}
                    />
                  );
                })}
            </tbody>
          </Table>
          {scenarios && (
            <MyPagination
              current={scenarioPageNumber}
              last={filteredScenarios.lastPage}
              itemsPerPage={numScenariosPerPage}
              handItemsPerPageChange={(num) => {
                setNumScenariosPerPage(num);
                setScenarioPageNumber(1);
              }}
              handlePageChange={(pg) => setScenarioPageNumber(pg)}
            />
          )}
        </Col>
        {tabActive && (
          <>
            {selectedScenarios.map((scen, i) => {
              return (
                <MapPanel
                  scen={scen}
                  myKey={i}
                  refreshCompareMaps={refreshCompareMaps}
                />
              );
            })}
          </>
        )}
      </Row>
    </Container>
  );
}

function TableRow({ data, myKey, handleCheckAddition, handleCheckRemoval }) {
  const [checked, setChecked] = useState(false);
  return (
    <tr key={myKey}>
      <td>
        <Form.Check
          type='switch'
          id='custom-switch'
          checked={checked}
          onClick={() => {
            if (checked) {
              handleCheckRemoval(data);
              setChecked(!checked);
            } else {
              if (handleCheckAddition(data)) {
                setChecked(!checked);
              }
            }
          }}
        />
      </td>
      <td>{data.Name}</td>
      <td>{data.Description === '' ? '-' : data.Description}</td>
      <td>
        {data.CountyName === '' ? '-' : data.CountyName.replaceAll(',', ', ')}
      </td>
      <td>{data.Region === '' ? '-' : data.Region.replaceAll(',', ', ')}</td>
      <td>
        {data.RoadType === '' ? '-' : data.RoadType.replaceAll(',', ', ')}
      </td>
      <td>
        {data.RoadOwnership === ''
          ? '-'
          : data.RoadOwnership.replaceAll(',', ', ')}
      </td>
    </tr>
  );
}

function MapPanel({ scen, myKey, refreshCompareMaps }) {
  const [showMap, setShowMap] = useState(true);
  const [showTable, setShowTable] = useState(false);

  const uniqueKey = `${scen.Name}-${myKey}-${refreshCompareMaps}`;

  return (
    <Col fluid key={uniqueKey}>
      <h5>Scenario Name: {scen.Name}</h5>
      <div className='pt-2'>
        <ToggleButton
          size='sm'
          className='m-2 text-light'
          id={`show-map-check-${uniqueKey}`}
          type='checkbox'
          variant='outline-primary'
          checked={showMap}
          onClick={() => {
            setShowMap(!showMap);
            setShowTable(!showTable);
          }}
        >
          Map View
        </ToggleButton>
        <ToggleButton
          size='sm'
          className='m-2 text-light'
          id={`show-table-check-${uniqueKey}`}
          type='checkbox'
          variant='outline-primary'
          checked={showTable}
          onClick={() => {
            setShowMap(!showMap);
            setShowTable(!showTable);
          }}
        >
          Table View
        </ToggleButton>
      </div>
      {showMap && (
        <Map
          county={scen.CountyName}
          region={scen.Region}
          roadType={scen.RoadType}
          roadOwner={scen.RoadOwnership}
          columns={scen.Columns}
        />
      )}
      {showTable && (
        <RanksTableTab
          rankerInfo={{
            county_name: scen.CountyName,
            road_type: scen.RoadType,
            region_name: scen.Region,
            road_ownership: scen.RoadOwnership,
            columns: scen.Columns
          }}
          defaultItemsPerPage={10}
        />
      )}
    </Col>
  );
}
