import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

export default function MultiDropDown({
  options,
  onSelectFunction,
  id,
  itemIDPrefix,
  selected,
  setSelected
}) {
  return (
    <DropdownButton
      size='sm'
      title={selected.length > 0 ? 'Filtering by...' : 'Select...'}
      id={id}
      variant='warning'
      menuVariant='dark'
      onSelect={() => {
        if (onSelectFunction) {
          onSelectFunction();
        }
      }}
    >
      {options &&
        options.map((val) => {
          return (
            <Dropdown.Item as='button' eventKey={val} key={val}>
              <Check
                value={val}
                itemIDPrefix={itemIDPrefix}
                selected={selected}
                setSelected={setSelected}
              />
            </Dropdown.Item>
          );
        })}
      <Dropdown.Item
        as='button'
        eventKey='Clear'
        onClick={() => setSelected([])}
      >
        Clear selection
      </Dropdown.Item>
    </DropdownButton>
  );
}

function Check({ value, itemIDPrefix, selected, setSelected }) {
  return (
    <Form.Check
      key={'check-' + value}
      type='checkbox'
      id={`${itemIDPrefix}-${value}`}
      label={value}
      value={value}
      checked={selected.includes(value)}
      onChange={() => {
        if (selected.includes(value)) {
          setSelected(
            selected.filter((x) => x !== value)
              ? selected.filter((x) => x !== value)
              : []
          );
        } else {
          setSelected(selected.concat([value]));
        }
      }}
    />
  );
}
