import Table from 'react-bootstrap/Table';

export default function RoadScores({ chartData }) {
  return (
    <div style={{ paddingTop: '1em', paddingBottom: '1em' }}>
      <h4>Road Scores</h4>
      {chartData && (
        <Table
          responsive
          striped
          bordered
          hover
          style={{ whiteSpace: 'nowrap', width: '1%' }}
          size='sm'
          variant='dark'
          className='text-light text-small'
        >
          <thead>
            <tr>
              <th>Factor</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {chartData.map((item) => {
              return (
                <tr>
                  <td>{item.column}</td>
                  <td>{item.value}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}
