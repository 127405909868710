// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useRef, useEffect } from 'react';

function useMiniMap(roadID, county, region, road_type, road_owner, columns) {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const mbMap = useRef();

  useEffect(() => {
    const myMap = new mapboxgl.Map({
      container: mbMap.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      attributionControl: false,
      bounds: [
        [-75.559614, 38.928519],
        [-73.893979, 41.357423]
      ],
      preserveDrawingBuffer: true
    });

    myMap.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl
      }),
      'top-right'
    );

    myMap.addControl(new mapboxgl.NavigationControl());

    myMap.on('load', () => {
      fetch('/api/individual_road_geom', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: roadID,
          county: county,
          region: region,
          road_type: road_type,
          road_owner: road_owner,
          columns: columns
        })
      })
        .then((r) => r.json())
        .then((data) => {
          myMap.addSource('individual_road', {
            type: 'geojson',
            // Use a URL for the value for the `data` property.
            data: data.geojson
          });
          myMap.addLayer({
            id: 'individual_road',
            type: 'line',
            source: 'individual_road',
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': data.color,
              'line-width': 8
            }
          });

          myMap.fitBounds(
            [
              [data.xmin, data.ymin],
              [data.xmax, data.ymax]
            ],
            {
              padding: 100
            }
          );
        });
    });

    return () => myMap.remove();
  }, [roadID, county, region, road_type, road_owner, columns]);

  return mbMap;
}

export default function MiniMap({
  roadID,
  county,
  region,
  road_type,
  road_owner,
  columns
}) {
  const mbMap = useMiniMap(
    roadID,
    county,
    region,
    road_type,
    road_owner,
    columns
  );
  return <div className='mini-map' ref={mbMap} id={`minimap-${roadID}`}></div>;
}
