import Spinner from 'react-bootstrap/Spinner';

export default function LoadingSpinner() {
  return (
    <div className='text-center'>
      <Spinner
        animation='border'
        role='status'
        variant='warning'
        className='spinner'
      >
        <span className='visually-hidden'>Loading...</span>
      </Spinner>
    </div>
  );
}
