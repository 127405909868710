import { useEffect, useState } from 'react';

export default function useAPIData(requestURL) {
  const [apiData, setAPIData] = useState();
  const [refresh, setRefresh] = useState(0);

  const forceRefresh = () => {
    setRefresh(refresh + 1);
  };

  useEffect(() => {
    if (!requestURL.includes('undefined')) {
      fetch(requestURL)
        .then((r) => r.json())
        .then((data) => setAPIData(data))
        .catch((error) => {
          console.error(`Error fetching API data from ${requestURL}: ` + error);
        });
    }
  }, [requestURL, refresh]);

  return [apiData, forceRefresh];
}
