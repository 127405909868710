// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   CartesianGrid,
//   Tooltip,
//   Legend,
//   ResponsiveContainer
// } from 'recharts';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
  Legend
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip'>
        <b>{`${label}: ${payload[0].value}`}</b>
      </div>
    );
  }

  return null;
};

export default function CustomRadarChart({ chartData }) {
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <RadarChart cx='50%' cy='53%' outerRadius='90%' data={chartData}>
        <Tooltip content={<CustomTooltip />} />
        <Legend verticalAlign='top' />
        <PolarGrid stroke='#fff' />
        <PolarAngleAxis dataKey='column' stroke='#fff' />
        <PolarRadiusAxis stroke='#fff' />
        <Radar
          name='Scores'
          dataKey='value'
          stroke='#cf2d5b'
          fill='#cf2d5b'
          fillOpacity={0.25}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
}
